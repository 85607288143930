.SkipNav {
  margin: 0;
  padding: 0;
  position: absolute;
    top: 0;
  transform: translateY(-100vh);
  
  &:focus {
    transform: translateY(0);
  }
}