html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.Site__main {
  flex: 1;
}